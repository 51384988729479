import axios from 'axios';

const customFetch = axios.create({
    baseURL: "https://jobzback.balasravatsal.tech/api/v1",
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true, // if you need to send cookies along with requests
});


export default customFetch;
